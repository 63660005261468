/* istanbul ignore file */
import { MenuWrapper, useRequestInit } from "adviesbox-shared";
import React, { ReactElement } from "react";
import brandLogoIcon from "../assets/new-brand-logo.svg";
import brandCollapsedLogoIcon from "../assets/new-collapsed-brand-logo.svg";
import { menuConfig } from "./menu-config";
import { DossierRouteParams } from "../shared/types";
import { useHistory } from "react-router-dom";

type NavigationListProps = {
  collapsed: boolean;
  collapse(): void;
};

const NavigationList = ({ collapsed, collapse }: NavigationListProps): ReactElement => {
  const { params } = useRequestInit<DossierRouteParams>();
  const history = useHistory();

  return (
    <MenuWrapper
      brandLogoIcon={brandLogoIcon}
      brandCollapsedLogoIcon={brandCollapsedLogoIcon}
      collapse={collapse}
      collapsed={collapsed}
      hasMenuInfo={true}
      loading={false}
      menuConfig={menuConfig(params)}
      appName={"hdn"}
      onClickCallback={/* istanbul ignore next */ () => history.push(`/vestiging/${params.vestiging}/zoeken`)}
    />
  );
};

NavigationList.displayName = "NavigationList";

export default NavigationList;
