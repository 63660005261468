import {
  Icon,
  FormFirstFocus,
  ZoekschermHeader,
  ZoekschermTopNavbar,
  ZoekschermHeaderColumn,
  ZoekschermProductSelect,
  ZoekschermHeading,
  ZoekschermBody,
  ZoekschermItem,
  Notificaties2Menu,
  NotificatiesMenu,
  useFeature,
  SupportMenu,
  AdviseurMenu,
  SupportTicketModalAjax,
  SupportRemoteAssistanceModal,
  AuthContext,
  useRequestInit,
  InvalidAdviesboxResultErrorPage
} from "adviesbox-shared";
import { Form } from "formik";
import React, { ReactElement, useState, useContext } from "react";
import { withAdviesboxFormik } from "../shared/utils/with-adviesbox-formik";
import { zoekschermSchema, ZoekschermState } from "./infra/zoekscherm-schema";
import LaatsteDossiers from "./laatste-dossiers/laatste-dossiers";
import NewLogo from "../assets/new-brand-logo.svg";
// import logo from "../assets/new-collapsed-brand-logo.svg";
import Zoeken from "./zoeken/zoeken";
import classes from "./zoekscherm.module.scss";
import { Debug } from "../shared/components/formik/Debug";
import "./zoekscherm.scss";

const Zoekscherm = (): ReactElement => {
  const [showDossiers, setShowDossiers] = useState(true);
  const [showMeldingmaken, setShowMeldingMaken] = useState(false);
  const [showRemoteAssistance, setShowRemoteAssistance] = useState(false);
  const [showInvalidAdviesboxResultErrorPage, setShowInvalidAdviesboxResultErrorPage] = useState(false);

  const featureNotificaties2 = useFeature("FeatureNotificaties2");
  const { user } = useContext(AuthContext);
  const { params } = useRequestInit();

  if (showInvalidAdviesboxResultErrorPage) {
    return <InvalidAdviesboxResultErrorPage />;
  }

  /*istanbul ignore next*/
  const toggleShowDossiers = (): void => {
    setShowDossiers(!showDossiers);
  };

  return (
    <FormFirstFocus>
      <Form>
        <ZoekschermHeader>
          <ZoekschermTopNavbar>
            <ZoekschermHeaderColumn alignRight={false}>
              <ZoekschermProductSelect currentApp="HDN" logo={NewLogo} />
            </ZoekschermHeaderColumn>
            <ZoekschermHeaderColumn alignRight={true}>
              {/* <ZoekschermItem>
                <HomeButton home={false} homepageLogoClass={classes.homepage_logo} logo={logo} />
              </ZoekschermItem> */}
              <ZoekschermItem>
                <SupportMenu
                  showMeldingMaken={/*istanbul ignore next*/() => setShowMeldingMaken(true)}
                  showRemoteAssistance={/*istanbul ignore next*/() => setShowRemoteAssistance(true)}
                  classes={{
                    iconblack: classes.iconblack,
                    iconpadding: classes.iconpadding
                  }}
                />
              </ZoekschermItem>
              <ZoekschermItem>
                {!featureNotificaties2 && (
                  <div data-testid="NotificatiesMenu">
                    <NotificatiesMenu />
                  </div>
                )}
                {featureNotificaties2 && (
                  <div data-testid="NewNotificatiesMenu">
                    <Notificaties2Menu />
                  </div>
                )}
              </ZoekschermItem>
              <ZoekschermItem>
                <AdviseurMenu />
              </ZoekschermItem>
            </ZoekschermHeaderColumn>
            <ZoekschermHeading heading="Adviesbox HDN Dossier" />
            <Zoeken />
          </ZoekschermTopNavbar>
        </ZoekschermHeader>
        <ZoekschermBody>
          <div className="container">
            <div className={`card card-adviesbox ${classes.zoeken_cards}`}>
              <div
                onClick={toggleShowDossiers}
                className={`${classes.clickable} d-flex flex-row flex-wrap`}
                id="toggle-show-dossiers"
              >
                <h2 className={"m-0 align-self-center"}>Dossiers met laatst ontvangen berichten</h2>
                <div className={`${classes.icon} ml-auto`}>
                  <>
                    {/*istanbul ignore next */ !showDossiers && <Icon name="chevron" alt="pijl-omlaag" />}
                    {showDossiers && (
                      <div className={classes.chevron_up}>
                        <Icon name="chevron" alt="pijl-omhoog" />
                      </div>
                    )}
                  </>
                </div>
              </div>
              <LaatsteDossiers showDossiers={showDossiers} />
            </div>
            <Debug />
          </div>
        </ZoekschermBody>
        {/* istanbul ignore next */ showMeldingmaken && user && (
          <SupportTicketModalAjax
            adviesdossierId={params.adviesdossier ?? null}
            user={user}
            closeModal={() => setShowMeldingMaken(false)}
            showInvalidResultErrorPage={() => setShowInvalidAdviesboxResultErrorPage(true)}
          />
        )}
        {/* istanbul ignore next */ showRemoteAssistance && (
          <SupportRemoteAssistanceModal closeModal={() => setShowRemoteAssistance(false)} />
        )}
      </Form>
    </FormFirstFocus>
  );
};

Zoekscherm.displayName = "Zoekscherm";

export default withAdviesboxFormik<ZoekschermState, ZoekschermState>({
  mapPropsToValues: (val): ZoekschermState => val,
  validationSchema: zoekschermSchema
})(Zoekscherm);
